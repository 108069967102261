<template>
  <div class="sidebar-holder">
    <div class="sidebar">
      <span class="sidebar-heading"></span>
      <ul class="sidebar-links">
        <li
          v-for="(sidebarLink, index) in sidebarData"
          :key="'sidebarLink' + index"
        >
          <b-link
            :to="`/` + sidebarLink.category.url_path"
            v-if="sidebarLink.category != null"
            >{{ sidebarLink.title }}</b-link
          >
          <span v-else>{{ sidebarLink.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarLinks",
  props: ["sidebarData"],
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>


